
// material-ui
// import { useTheme } from '@mui/material/styles';
import { UserContext } from 'layout/MainLayout';

import { Stack, Avatar } from '@mui/material';
// project imports
// import UpgradePlanCard from './UpgradePlanCard';
// import User1 from 'assets/images/users/user-round.svg';
import { useContext } from 'react';
import { lengthOfObject } from 'views/versions/tabs/Constfunctions';
// assets
import { deepOrange } from '@mui/material/colors';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {


  // using the Context value 
  const user = useContext(UserContext);

  const stringAvatar = (user) => {
    return {
      children: `${user.split(' ')[0][0]}${user.split(' ')[1][0]}`,
    };
  }

  return (
    <Stack direction="row" spacing={2}
      sx={{
        height: '48px',
        alignItems: 'center',
        fontWeight: 'bold',
      }}
    >
      <Avatar {...stringAvatar(`${user.FirstName} ${user.LastName}`)} sx={{fontWeight: 'normal', bgcolor: deepOrange[300], color: '#fff', margin: '0 20px 0 3px !important'}}/>
      {user && lengthOfObject(user) !== 0 ? user.FirstName + ' ' + user.LastName : 'Guest'}
    </Stack>
  );
};

export default ProfileSection;
