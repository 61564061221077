import React, { useState,useEffect } from 'react';
import {TextField } from '@mui/material';
import { currencyFormatter } from '../tabs/Constfunctions';

const EditableTableCell = ({ value, onChange,type,dollarSign,view}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [tempValue, setTempValue] = useState(value);
    const [tempView, setTempView] = useState(view);
  //  console.log(view);
    useEffect(()=>{
      setTempValue(value);
    },[value]);
    
    useEffect(()=>{
      setTempView(view);
    },[view]);

    const handleDoubleClick = () => {
      //to check wheather to have editable cell or not (view mode or edit mode)
      tempView? setIsEditing(false): setIsEditing(true);
      
   };

  //  const display=()=>{
  //   if(dollarSign==="true" && todecimalPoint==="true") return currencyFormatter(parseFloat(value).toFixed(2))
  //   if(dollarSign==="true" && todecimalPoint==="false") return currencyFormatter(value)
  //   else return value
  //  };
  
    const handleChange = (e) => {
      const newValue = e.target.value;
      // Check if the new value is a valid number before updating state
      if(type==='number'){
      if (!isNaN(newValue)) {
        setTempValue(newValue);
      }
    }
      else {
        setTempValue(newValue);
      }
  
    };
  
    const handleBlur = () => {
      setIsEditing(false);
    //   onChange(parseFloat(tempValue));
      if(type==='number')onChange(parseFloat(tempValue));
      else onChange(tempValue);
      
    };
  
    return isEditing ? (
      <TextField
        value={tempValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
        fullWidth
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleBlur();
          }
        }}
      />
    ) : (
      <div role='presentation' style={{height:"27px"}} onClick={handleDoubleClick} onKeyUp={handleDoubleClick}>
        
        
        {/* if(dollarSign==="true" and todecimalPoint==="true"){currencyFormatter(value.toFixed(2))}
        if(dollarSign==="true" and todecimalPoint==="false"){currencyFormatter(value)}
        else{value} */}
        
        {dollarSign==="true"?currencyFormatter(value):value}
        {/* {display()} */}
      </div>
    );
  };

  export default EditableTableCell;