import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import img401 from './401-status-code.webp'

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/login');
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px' }}>
      <img src={img401} alt="401 Unauthorized" style={{ width: '40%', marginBottom: '20px' }} />
      <Typography variant="h1" component="h2" gutterBottom>
        Unauthorized Access
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
        You do not have permission to view this page.
      </Typography> */}
      <Button variant="contained" color="secondary" onClick={handleGoBack}>
        Go to Login
      </Button>
    </Container>
  );
};

export default Unauthorized;
