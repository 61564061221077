import React from 'react'
import {Stack} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Spinnerprogress = () => {
  return (
    
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{mt:2}}>
        <CircularProgress color="secondary" />
    </Stack>
  )
}

export default Spinnerprogress;