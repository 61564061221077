import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axiosInstance from 'interceptors/axiosInstance';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setupEntityAPI, detailedScheduleTaskAPI, saveDetailedScheduleTaskAPI } from 'api/api';
import Spinnerprogress from 'ui-component/circular-progress/Spinnerprogress';
// import { isEqual } from 'lodash';

export default function DetailedScheduleTask({ view, setTab, ClientId, ProjectId, EstimationId, Version}) {

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const detailedValidationProp={
  //   InstanceTypeValue: 'NA',
  //   ProgramTypeValue: 'NA',
  //   ProgramFeatureValue: 'NA',
  //   EnableGamificationValue: 'No',
  //   EnableLnEValue: 'No',
  //   EnableSSOValue: 'No',
  //   SSNVaultValue: 'No',
  //   DataMigrationNeededValue: 'No',
  // }
  const [entityType, setEntityType] = useState({
    InstanceTypeValue: [],
    ProgramTypeValue: [],
    ProgramFeatureValue: [],
    EnableGamificationValue: [],
    EnableLnEValue: [],
    EnableSSOValue: [],
    SSNVaultValue: [],
    DataMigrationNeededValue: [],
  })

  const getInitialState = (entity) => {
    var initialEntity = '';
    entity.filter(item => {
      if (item.Select) {
        initialEntity = item.Text
        return (item.Text);
      }
    })
    return initialEntity;
  }


  const [formState, setFormState] = useState(
    {
      InstanceTypeValue: 'NA',
      ProgramTypeValue: 'NA',
      ProgramFeatureValue: 'NA',
      EnableGamificationValue: 'No',
      EnableLnEValue: 'No',
      EnableSSOValue: 'No',
      SSNVaultValue: 'No',
      DataMigrationNeededValue: 'No',

    }
  );




  const handleChange = async (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    await axiosInstance.post(saveDetailedScheduleTaskAPI, { ClientId: ClientId, ProjectId: ProjectId, EstimationID: EstimationId, Version: Version, ...formState, [e.target.name]: e.target.value });
    
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setTab('Client-Custom-Change');
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchEntity = async () => {

      // All api for dropdown
      const instanceRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Instance Type&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const programRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Program Type&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const programFeatureRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Program Feature&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const enableGamificationRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Enable Gamification&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const enableLearnEarnRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Enable Learn n Earn&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const enableSSORes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Enable SSO&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const enableSSNVaultRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=SSN Vault&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      const dataMigrationRes = await axiosInstance.get(`${setupEntityAPI}?EntityType=Data Migration Needed&EstimationId=${EstimationId}`).catch((error) => console.log(error));

      // api for publish validation
      // const publishValidationRes = await axios.get(`${publishValidationAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).catch((error) => console.log(error));
 
      setEntityType({
        ...entityType, InstanceTypeValue: instanceRes.data, ProgramTypeValue: programRes.data,
        ProgramFeatureValue: programFeatureRes.data, EnableGamificationValue: enableGamificationRes.data,
        EnableLnEValue: enableLearnEarnRes.data, EnableSSOValue: enableSSORes.data,
        SSNVaultValue: enableSSNVaultRes.data, DataMigrationNeededValue: dataMigrationRes.data

      });

      setFormState({
        ...formState, InstanceTypeValue: getInitialState(instanceRes.data),
        ProgramTypeValue: getInitialState(programRes.data), ProgramFeatureValue: getInitialState(programFeatureRes.data),
        EnableGamificationValue: getInitialState(enableGamificationRes.data), EnableLnEValue: getInitialState(enableLearnEarnRes.data),
        EnableSSOValue: getInitialState(enableSSORes.data), SSNVaultValue: getInitialState(enableSSNVaultRes.data),
        DataMigrationNeededValue: getInitialState(dataMigrationRes.data)

      })
      
     
      setIsLoading(false);
      // console.log(rowResponse);
    }
    fetchEntity();


  }, [])

  useEffect(() => {
    const fetchRows = async () => {
      // api for data rows
      const rowResponse = await axiosInstance.get(`${detailedScheduleTaskAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).catch((error) => console.log(error));
      if(rowResponse.data){
        setRows([...rowResponse.data]);
      }
    }
    fetchRows();
    
  }, [formState]);

  // useEffect(() => {
  //   //validation for publish
  //   isEqual(formState,detailedValidationProp)? setCanPublish({...canPublish, DetailedScheduleTab:"False"}):setCanPublish({...canPublish, DetailedScheduleTab:"True"});
      
  // }, [formState]);

  return (
    <>
      {
        isLoading ? <Spinnerprogress /> : <Box>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="instance-type-label">Instance Type</InputLabel>
            <Select
              labelId="instance-type-label"
              id="instance-type"
              name="InstanceTypeValue"
              value={formState.InstanceTypeValue}
              onChange={handleChange}
            >
              {
                entityType.InstanceTypeValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="program-type-label">Program Type (Points/Dollar)</InputLabel>
            <Select
              labelId="program-type-label"
              id="program-type"
              name="ProgramTypeValue"
              value={formState.ProgramTypeValue}
              onChange={handleChange}
            >
              {
                entityType.ProgramTypeValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="program-feature-label">Program Feature(Sales Incentive/Recognition/Both)</InputLabel>
            <Select
              labelId="program-feature-label"
              id="program-feature"
              name="ProgramFeatureValue"
              value={formState.ProgramFeatureValue}
              onChange={handleChange}
            >
              {
                entityType.ProgramFeatureValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="enable-gamification-label">Enable Gamification</InputLabel>
            <Select
              labelId="enable-gamification-label"
              id="enable-gamification"
              name="EnableGamificationValue"
              value={formState.EnableGamificationValue}
              onChange={handleChange}
            >
              {
                entityType.EnableGamificationValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="enable-learn-earn-label">Enable Learn n Earn</InputLabel>
            <Select
              labelId="enable-learn-earn-label"
              id="enable-learn-earn"
              name="EnableLnEValue"
              value={formState.EnableLnEValue}
              onChange={handleChange}
            >
              {
                entityType.EnableLnEValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="enable-sso-label">Enable SSO</InputLabel>
            <Select
              labelId="enable-sso-label"
              id="enable-sso"
              name="EnableSSOValue"
              value={formState.EnableSSOValue}
              onChange={handleChange}
            >
              {
                entityType.EnableSSOValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="enable-ssn-vault-label">SSN Vault</InputLabel>
            <Select
              labelId="enable-ssn-vault-label"
              id="enable-ssn-vault"
              name="SSNVaultValue"
              value={formState.SSNVaultValue}
              onChange={handleChange}
            >
              {
                entityType.SSNVaultValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl disabled={view} variant="filled" sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="enable-data-migration-label">Data Migration Needed</InputLabel>
            <Select
              labelId="enable-data-migration-label"
              id="enable-data-migration"
              name="DataMigrationNeededValue"
              value={formState.DataMigrationNeededValue}
              onChange={handleChange}
            >
              {
                entityType.DataMigrationNeededValue.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.Text}>{item.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>

          <TableContainer sx={{ marginTop: '20px', maxHeight: 450 }} >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className='detail__tr'>

                  <TableCell >Phase</TableCell>
                  <TableCell sx={{ minWidth: 340 }} >Activity/Deliverable</TableCell>
                  <TableCell >Type</TableCell>
                  <TableCell >Depend</TableCell>
                  <TableCell >Owner</TableCell>
                  <TableCell >Configure/ Build Work Type</TableCell>
                  <TableCell >Client Complexity</TableCell>
                  <TableCell className='text__right'>Base Effort Hours</TableCell>
                  <TableCell className='text__right' >Pending Feature Build Hours</TableCell>
                  <TableCell className='text__right'>Original Estimated Hours</TableCell>
                  <TableCell sx={{ minWidth: 300 }}>Comments</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: row.Enabled === 'Yes' ? '#ccfacc96' : '' }}

                  >

                    <TableCell>{row.Phase}</TableCell>
                    <TableCell>{row.Activity}</TableCell>
                    <TableCell>{row.Type}</TableCell>
                    <TableCell>{row.Depend}</TableCell>
                    <TableCell>{row.Owner}</TableCell>
                    <TableCell>{row.BuildWorkType}</TableCell>
                    <TableCell>{row.Enabled}</TableCell>
                    <TableCell className='text__right'>{row.BaseEffort}</TableCell>
                    <TableCell className='text__right'>{row.PendingFeatureHour}</TableCell>
                    <TableCell className='text__right'>{row.OriginalEstimatedHours}</TableCell>
                    <TableCell>{row.Comments}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '20px', visibility: view ? 'hidden' : 'visible' }} onClick={handleNext}>
            Next<NavigateNextIcon/>
          </Button>

        </Box>
      }
    </>
  );
}
