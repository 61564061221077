// assets
import { IconLogout} from '@tabler/icons-react';

// constant
const icons = { IconLogout};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //


const logout = {
  "Key": 'logout',
  "Type": 'group',
  "Url": '/login',
  "Items": [
    {
      "Key": 'logout',
      "Text": 'Logout',
      "Type": 'item',
      "Url": '/login',
      icon: icons.IconLogout,
      breadcrumbs: false
    }
  ]
};

export default logout;
