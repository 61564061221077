import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_LOCAL_PATH}`,
});


axiosInstance.interceptors.request.use(
    (config) => {
        const JWToken = sessionStorage.getItem("JWToken");
        if (JWToken) {
            config.headers.Authorization = `${JWToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log(error);
        if ((error.response.status== 500 || error.response.status== 401) && error.response) {
            window.location.href = '/401';
        }
        return Promise.reject(error);
    }
);



export default axiosInstance;