import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { useSearchParams } from 'react-router-dom';
// assets
import { IconChevronRight } from '@tabler/icons-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { ssoLoginAPI } from 'api/api';
import axios from 'axios';
// import Login from 'views/pages/authentication/authentication3/Login3';
import Loader from 'ui-component/Loader';
import {AppContext} from 'App';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }
      : {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));


  // Creating an userContext API
  const UserContext = createContext();

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };


  // For sso
  const [searchParams] = useSearchParams();
  const [authorize, setAuthorize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({})
  const navigate = useNavigate();

  const token = searchParams.get('token');

  // Consumer of AppContext API
  const {cookieValue} = useContext(AppContext);
  const [cookie, setCookie] = cookieValue;

  useEffect(() => {
    setIsLoading(true);
    const fetchUser = async () => {
      await axios.post(`${ssoLoginAPI}?token=${token}`).then((response) => {
        console.log(response);
        if(response.data.ResponseMessage === 'Success') {
          setAuthorize(true);
          navigate('/home');
          setCookie('Token', token, { path: '/' });
          sessionStorage.setItem("JWToken", response.data.Data.Token);
          setUser(response.data.Data);
        }
        else {
          navigate('/login');
        }

      }).catch((error) => console.log(error));
      setIsLoading(false);
    }

    if (cookie.Token) {
      axios.post(`${ssoLoginAPI}?token=${cookie.Token}`).then((response) => {
        if (response.data.ResponseMessage === 'Success') {
          setAuthorize(true);
          sessionStorage.setItem("JWToken", response.data.Data.Token);
          setUser(response.data.Data);
        }
        else {
          // console.log('cookie not present');
          navigate('/login');
        }

      }).catch((error) => console.log(error));
      setIsLoading(false);
    }
    else if (!token) {
      navigate('/login');
    }
    else {
      // console.log('cookie not present');
      fetchUser();
    }
  }, []);


  return (
    <>
      {isLoading ? <Loader /> :
        <>
          {authorize ? <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
              enableColorOnDark
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{
                bgcolor: theme.palette.background.default,
                transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
              }}
            >
              <Toolbar sx={{ background: '#163d5c', padding: '5px' }}>
                <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
              </Toolbar>
            </AppBar>

            {/* drawer */}
            <UserContext.Provider value={user}>
              <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            </UserContext.Provider>

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
              {/* breadcrumb */}
              <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
              <Outlet />
            </Main>
            {/* <Customization /> */}
          </Box>
            : ''}
        </>
      }
    </>
  );
};

export default MainLayout
export { UserContext };