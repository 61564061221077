// import React, { useEffect,useState } from 'react';
import { Table, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import EditableTableCell from '../../component/EditableTableCell';
import { currencyFormatter } from '../Constfunctions';

const EditableTable = ({ rows, handleCellChange, totaleffort, totalcost, weeks, view,weekhide }) => {
  
  return (
    <TableContainer>
      <Table>
        <TableHead className='table__header'>
          <TableRow>
            <TableCell sx={{ maxWidth: '270px', minWidth: "250px" }}>
              Role
            </TableCell>
            <TableCell sx={{ maxWidth: '80px', minWidth: '80px' }} className='text__right'>
              FTE
            </TableCell>
            <TableCell className='text__right'>
              Efforts
            </TableCell>
            <TableCell sx={{ minWidth: '100px' }} className='text__right'>
              Cost Hr Rate
            </TableCell>
            <TableCell sx={{ minWidth: '100px' }} className='text__right'>
              Cost
            </TableCell>
            <TableCell sx={{minWidth: '150px'}}>
              Comment
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 && rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>{row.Role}</TableCell>
              <TableCell className='text__right editable__feild' sx={{width: '10%'}}>
                <EditableTableCell
                  view={view}
                  value={row.FTE}
                  dollarSign="false"
                  todecimalPoint="false"
                  type="number"
                  onChange={(newValue) =>
                    handleCellChange(newValue, rowIndex, 1)
                  }
                  className='text__right'
                />
              </TableCell>
              <TableCell className='text__right'>{row.Effort}</TableCell>
              <TableCell className='text__right editable__feild' sx={{width: '12%'}}>
                <EditableTableCell
                  view={view}
                  type="number"
                  dollarSign="true"
                  todecimalPoint="true"
                  className='text__right'
                  value={row.CostHrRate}
                  onChange={(newValue) =>
                    handleCellChange(newValue, rowIndex, 3)
                  }
                />
              </TableCell>
              <TableCell className='text__right'>{currencyFormatter(row.Cost)}</TableCell>
              <TableCell className='editable__feild'  sx={{width: '22%'}}>
                <EditableTableCell
                  view={view}
                  type="string"
                  dollarSign="false"
                  todecimalPoint="false"
                  className='text__right'
                  value={row.Comments}
                  onChange={(newValue) =>
                    handleCellChange(newValue, rowIndex, 5)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow className='row-total table__header'>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell className='text__right'>{totaleffort}</TableCell>
            <TableCell></TableCell>
            <TableCell className='text__right'>{currencyFormatter(totalcost)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{display: weekhide ? 'none' : ''}}>
            <TableCell colSpan={6} sx={{ borderBottom: "0px solid" }}></TableCell>
          </TableRow>
          <TableRow sx={{display: weekhide ? 'none' : 'contents'}}>
            <TableCell colSpan={4}>Week Duration</TableCell>
            <TableCell className='text__right'>{weeks}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditableTable;

