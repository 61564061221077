import * as React from 'react';
import { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { ClientTable } from "../../component/ClientTable";
import { Modal } from "../../component/Modal";
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { clientCustomDetailsAPI, insertUpdateClientCustomChangeAPI, deleteClientCustomChangeAPI } from 'api/api';
import Swal from 'sweetalert2';
// import { number } from 'prop-types';
import Spinnerprogress from 'ui-component/circular-progress/Spinnerprogress';
import axiosInstance from 'interceptors/axiosInstance';

function ClientCustomChange({ view, setTab, ClientId, ProjectId, EstimationId, Version }) {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [productRoadmap, setProductValue] = useState(0);
  const [clientDelivery, setclientDeliveryValue] = useState(0);
  const [billedToClient, setBilledToClient] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshRows, setRefreshRows] = useState([]);
  useEffect(() => {

    const fetchRows = async () => {
      setIsLoading(true);
      // api for data rows
      const rowResponse = await axiosInstance.get(`${clientCustomDetailsAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).catch((error) => console.log(error));
      if(rowResponse.data){
        setRows([...rowResponse.data]);
      }

      var initialproduct = 0;
      var initialdelivery = 0;
      var initialbilledtoclient = 0;

      // rowResponse.data.map((row) => {
      //   row.DevOwner === 'Product' ? initialproduct = initialproduct + parseInt(row.StoryPoints) : initialdelivery = initialdelivery + parseInt(row.StoryPoints);
      // });

      const SumofBilledandProduct = (StoryPoints, billToClient) => {
        initialproduct = initialproduct + parseInt(StoryPoints);
        initialbilledtoclient = initialbilledtoclient + StoryPoints * (billToClient / 100);
      }

      rowResponse.data.map((row) => {
        row.DevOwner === 'Product' ? SumofBilledandProduct(row.StoryPoints, row.BillToClient) : initialdelivery = initialdelivery + parseInt(row.StoryPoints);
      });

      setProductValue(initialproduct);
      setclientDeliveryValue(initialdelivery);
      setBilledToClient(initialbilledtoclient);
      setIsLoading(false);
    }

    fetchRows();


  }, [refreshRows]);


  const handleDeleteRow = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosInstance.delete(deleteClientCustomChangeAPI, { data: { Id: id, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version } }).then(() => {
          const updatedRow = rows.filter((r) => r.ID !== id);
          setRefreshRows(updatedRow);
        }).catch((error) => console.log(error));

      }
    });

    // rows[targetIndex].developmentowner==='Product'?setProductValue((prev)=>{prev-rows[targetIndex].storypoints}):setclientDeliveryValue((prev)=>{prev-rows[targetIndex].storypoints});
  };

  const handleCloseModal = (e, reason) =>{
    if (reason !== 'backdropClick') {
      setOpen(false);
      setRowToEdit(null);
    }
  }

  const handleEditRow = (index, id) => {

    setRowToEdit(index);
    console.log(id);
    setOpen(true);

  };

  const handleSubmit = async (newRow) => {

    if (rowToEdit === null) {
      await axiosInstance.post(insertUpdateClientCustomChangeAPI, { ...newRow, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
      setRefreshRows([...rows, newRow]);
    }

    else {
      await axiosInstance.post(insertUpdateClientCustomChangeAPI, { ...newRow, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
      setRefreshRows(
        rows.map((currRow, idx) => {
          if (idx !== rowToEdit) return currRow;
          return newRow;
        })
      );
    }
    // newRow.developmentowner==='Product'?setProductValue((prev)=>{prev+newRow.storypoints}):setclientDeliveryValue((prev)=>{prev+newRow.storypoints});
  };

  const handleNext = (e) => {
    e.preventDefault();
    setTab('Team-&-Cost');
  }

  const handleBack = (e) => {
    e.preventDefault();
    setTab('Detailed Schedule & Tasks');
  }

  return (
    <>
      {isLoading ? <Spinnerprogress /> : <Box>
        <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={() => setOpen(true)} sx={{ visibility: view ? 'hidden' : 'visible' }}>
          <><AddIcon />Add</>
        </Button >
        <ClientTable view={view} rows={rows} deleteRow={handleDeleteRow} editRow={handleEditRow} productRoadmap={productRoadmap} clientDelivery={clientDelivery} billedToClient={billedToClient} />

        <Modal open={open}
          closeModal={handleCloseModal}
          onSubmit={handleSubmit}
          defaultValue={rowToEdit !== null && rows[rowToEdit]} />
        <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleBack} sx={{ marginTop: '20px', marginRight: '20px', visibility: view ? 'hidden' : 'visible' }}>
          <NavigateBeforeIcon />Back
        </Button>
        <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleNext} sx={{ marginTop: '20px', visibility: view ? 'hidden' : 'visible' }}>
          Next<NavigateNextIcon />
        </Button>
      </Box>

      }
    </>
  );
}

export default ClientCustomChange;