import * as React from 'react';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Notification=({openNotification,setOpenNotification,message})=> {
  const vertical='top'
  const horizontal='right'
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotification(false);
  };

  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar open={openNotification} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{vertical,horizontal}}>
        <Alert
          onClose={handleClose}
          severity="success"
        //   variant="filled"
          sx={{ width: '100%',backgroundColor:'#eef2f6',boxShadow:'0 0 5px 0 black'}}
        >
        {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Notification;