
// For Menu
export const menuAPI = `/pub/Home/GetMainNavigationMenu`;

export const createClientAPI = `/pub/Config/CreateClient`;

export const createEstimationAPI = `/pub/Home/CreateEstimation`;

// For Estimation Version
export const estimationAPI = `/pub/Home/GetEstimations`;

export const updateEstimationStatus =`/pub/Home/UpdateEstimationStatus`;

export const createEstimationVersionAPI = `/pub/Home/CreateEstimationVersion`;

export const publishValidationAPI = `/pub/Home/GetEstimationValidationToPublish`;

export const deleteEstimationVersionAPI = `/pub/Home/DeleteEstimationVersion`

// For Detail Schedule Task Tab
export const setupEntityAPI = `/pub/Config/GetSetupEntity`;

export const detailedScheduleTaskAPI = `/pub/DetailScheduleTask/GetDetailScheduleTaskByEstimationId`;

export const saveDetailedScheduleTaskAPI = `/pub/DetailScheduleTask/SaveDetailScheduleTaskByEstimationId`;

// For Client Custom Change Tab
export const clientCustomDetailsAPI = `/pub/ClientCustomChange/GetclientCustomDetails`;

export const insertUpdateClientCustomChangeAPI = `/pub/ClientCustomChange/InsertUpdateClientCustomChange`;

export const deleteClientCustomChangeAPI = `/pub/ClientCustomChange/DeleteClientCustomChange`;

// For Annual Support Cost Tab
export const getAnnualSupportDataAPI = `/pub/AnnualSupportCost/GetAnnualSupportCostByID`;

export const getImplementationTypeValuesAPI = `/pub/AnnualSupportCost/GetImplementationTypeValues`;

export const updateAnnualSupportCostAPI = `/pub/AnnualSupportCost/UpdateAnnualSupportCost`;

// For Assumption and questions tab

export const getAssumptionDataAPI = `/pub/QuestionsAndAssumptions/GetAssumptionByID`;

export const InsertUpdateAssumptionAPI = `/pub/QuestionsAndAssumptions/InsertUpdateAssumption`;

export const deleteAssumptionAPI = `/pub/QuestionsAndAssumptions/DeleteAssumptionByID`;

export const getQuestionDataAPI = `/pub/QuestionsAndAssumptions/GetQuestionByID`;

export const insertUpdateQuestionAPI = `/pub/QuestionsAndAssumptions/InsertUpdateQuestion`;

export const deleteQuestionAPI = `/pub/QuestionsAndAssumptions/DeleteQuestionByIDv`;

// For Team & Cost Tab

export const GetBuildTeamAPI = `/pub/TeamAndCost/GetBuildTeamByCostId`;

export const updateBuildTeamAPI = `/pub/TeamAndCost/UpdateBuildTeam`;

export const getCustomTeamAPI = `/pub/TeamAndCost/GetCustomTeamByCostId`;

export const updateCustomTeamAPI = `/pub/TeamAndCost/UpdateCustomTeam`;

//For publish Button
export const publishEstimation = `/Pub/Home/PublishEstimationVersion`;

// For sso
export const ssoLoginAPI = `${process.env.REACT_APP_LOCAL_PATH}/pub/Token/SSOLogin`;