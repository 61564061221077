import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { checkStartSpecialChar } from '../tabs/Constfunctions';

export const AssumptionQuestionModal = ({ column1, open, closeModal, onSubmit, defaultValue }) => {
  const initial = {
    AssumptionId: 0,
    Assumption: "",
    QuestionId: 0,
    Question: "",
  }

  useEffect(() => {
    const value = defaultValue || initial;
    setFormState(value);
  }, [open]);

  const [formState, setFormState] = useState(initial);
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const disablSaveBtn = useMemo(
    () => formState.Assumption === '' && formState.Question === ''
    ,
    [formState]
  )

  return (
    <React.Fragment>
      <Dialog sx={{ width: '100%' }}
        open={open}
        onClose={closeModal}
        PaperProps={{
          component: 'form',
          style: {
            maxWidth: '500px',
          },
          onSubmit: (event) => {
            event.preventDefault();
            onSubmit(formState);
            closeModal();
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%', display: 'grid', minWidth: '400px',
            }}>
            <TextField
              sx={{ m: 1 }}
              id={column1 === 'assumption' ? 'assumption' : 'question'}
              label={column1 === 'assumption' ? 'Assumption' : 'Question'}
              variant="filled"
              multiline
              maxRows={3}
              name={column1 === 'assumption' ? 'Assumption' : 'Question'}
              onChange={handleChange}
              onKeyDown={checkStartSpecialChar}
              value={column1 === 'assumption' ? formState.Assumption : formState.Question} />

            {/* <TextField
              sx={{ m: 1 }}
              id="addedBy"
              variant="filled"
              label="Added By"
              name="UpdatedBy"
              onChange={handleChange}
              value={formState.UpdatedBy}
            />


            <TextField
              sx={{ m: 1 }}
              id="addedOn"
              label="Added On"
              variant="filled"
              name="Updated"
              onChange={handleChange}
              value={formState.Updated}
            /> */}


          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color='secondary' >Cancel</Button>
          <Button type="submit" color='secondary' disabled={disablSaveBtn}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}