import { useState, useEffect } from "react";
import EditableTable from "./EditTable";
import EditableTableCell from '../../component/EditableTableCell';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from "@mui/material";
import { Button, Box } from "@mui/material";
import Spinnerprogress from "ui-component/circular-progress/Spinnerprogress";
import { currencyFormatter, precentageFormatter, SumofCoulmn } from '../Constfunctions';
import { getCustomTeamAPI, updateCustomTeamAPI, } from "api/api";
import Notification from "../../../../ui-component/notification/notification";
import axiosInstance from "interceptors/axiosInstance";
// import { set } from "immutable";

const ClientCustomCost = ({ view, buildSetupCost, ClientId, ProjectId, EstimationId, Version, setDirty, setNotDirty }) => {

    const weeklyHour = 40;
    const [clientEstimatedHour, setClientEstimatedHour] = useState(0);
    const[clientContengencyPrecentage,setClientContengencyPrecentage]=useState(0)
    const [clientEstimatedContengency, setClientEstimatedContengency] = useState(0);
    const [clientTotalPoint, setClientTotalPoint] = useState(0);
    const [clientStoryPoint,setClientStoryPoint] = useState(0);
    // const [productRoadmap, setProductRoadmap] = useState(0);
    // const [clientExtension, setClientExtension] = useState(0);
    // const [dataMigration,setDataMigration] = useState(0);
    const [BilledToClient,setBilledToClient] =useState(0);
    const[pointsBilled,setPointsBilled]=useState(0);
    const [productEstimatedHour, setProductEstimatedHour] = useState(0);
    const[productContengencyPrecentage,setProductContengencyPrecentage]=useState(0)
    const [productEstimatedContengency, setProductEstimatedContengency] = useState(0);
    const [productTotalPoint, setProductTotalPoint] = useState(0);
    const [productStoryPoint,setProductStoryPoint] = useState(0);
    const [productCost,setProductCost]=useState(0)
    const [totalDevHour,setTotalDevHour] = useState(0);
    const [DevHourPrecentage,setDevHourPrecentage]=useState([0,0,0,0]);
    
    const [clientNoOfSprint,setClientNoOfSprint] = useState(0); 
    const [productNoOfSprint,setProductNoOfSprint] = useState(0); 
    const [weeks,setWeeks] =useState(0);

    const [rows, setRows] = useState([]);
    const [totalcost, settotalcost] = useState(0);
    const [totaleffort, settotaleffort] = useState(0);
    const[costBreakout,setCostBreakout]=useState([buildSetupCost,0,0,0,0]);
    const[totalcostBreakout,setTotalCostBreakout]=useState(0);
    const [costBreakoutPrecentage,setCostBreakoutPrecentage]=useState([0,0,0,0,0]);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedFeild, setUpdatedFeild] = useState({

        FTE1: 0,
        Comment1: "",
        CostHrRate1: 0,
        FTE2: 0,
        Comment2: "",
        CostHrRate2: 0,
        FTE3: 0,
        Comment3: "",
        CostHrRate3: 0,
        FTE4: 0,
        Comment4: "",
        CostHrRate4: 0,
        FTE5: 0,
        Comment5: "",
        CostHrRate5: 0,
        FTE6: 0,
        Comment6: "",
        CostHrRate6: 0,
        FTE7: 0,
        Comment7: "",
        CostHrRate7: 0,
        Weeks: 0,
        EstimatedContengency: 0,
        StoryPoints: 0,
        DataMigration: 0,
        ProductContengency:0,
        ProductStoryPoints:0,
        ProductCostPerStoryPoint:0
    });


    useEffect(() => {
        setIsLoading(true);

        const fetchCustomCostData = async () => {
            await axiosInstance.get(`${getCustomTeamAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`)
                .then((response) => {
                    const initialRow=response.data.DigitalData;
                    const finalRow=[initialRow[5],initialRow[6],initialRow[0],initialRow[1],initialRow[2],initialRow[3],initialRow[4]];
                    setRows(finalRow);
                    const totalcost=SumofCoulmn(initialRow, 'Cost');
                    
                    // console.log(response.data.IntermediateCalculations)
                    const productRoadmap=response.data.IntermediateCalculations[0].value?response.data.IntermediateCalculations[0].value:0;// condition becoz if product roadmap value that come from api is null then we set its value to 0
                    // setProductRoadmap(productRoadmap); 
                    const clientExtension=response.data.IntermediateCalculations[1].value?response.data.IntermediateCalculations[1].value:0; // condition becoz if client extension value that come from api is null then we set its value to 0
                    // setClientExtension(clientExtension);  
                    // const dataMigration=response.data.IntermediateCalculations[2].value?response.data.IntermediateCalculations[2].value:0; // condition becoz if data migration value that come from api is null then we set its value to 0
                    // setDataMigration(dataMigration);
                    

                    setClientEstimatedHour(clientExtension); // condition becoz if estimation point value that come from api is null then we set its value to 0
                    const clientContengencyPrecentage = Math.ceil(response.data.IntermediateCalculations[6].value?response.data.IntermediateCalculations[6].value:0);
                    setClientContengencyPrecentage(clientContengencyPrecentage)
                    const clientContengency=Math.ceil((clientContengencyPrecentage*clientExtension)/100)
                    const clienttotalPoint = clientContengency + clientExtension
                    setClientEstimatedContengency(clientContengency);
                    setClientTotalPoint(clienttotalPoint);
                    
                    setProductEstimatedHour(productRoadmap)
                    const productContengencyPrecentage=Math.ceil(response.data.IntermediateCalculations[8].value?response.data.IntermediateCalculations[8].value:0);
                    setProductContengencyPrecentage(productContengencyPrecentage)
                    const productContengency=Math.ceil((productContengencyPrecentage*productRoadmap)/100)
                    setProductEstimatedContengency(productContengency)
                    const producttotalPoint = productRoadmap + productContengency
                    setProductTotalPoint(producttotalPoint)
                    const pointsBilled=response.data.IntermediateCalculations[5].value?response.data.IntermediateCalculations[5].value:0; 
                    setPointsBilled(pointsBilled) 
                    const billedClient=Math.ceil(pointsBilled*productContengencyPrecentage/100)+pointsBilled
                    setBilledToClient(billedClient);

                    const clientStoryPoint=response.data.IntermediateCalculations[10].value
                    setClientStoryPoint(clientStoryPoint)
                    const productStoryPoint=response.data.IntermediateCalculations[11].value
                    setProductStoryPoint(productStoryPoint)
                    const productCostPerStory=response.data.IntermediateCalculations[12].value
                    setProductCost(productCostPerStory)
                    
                    const clientnoOfSprint=Math.ceil(clienttotalPoint /clientStoryPoint);
                    setClientNoOfSprint(clientnoOfSprint);
                    setWeeks(clientnoOfSprint*2);

                    const productnoOfSprint=Math.ceil(producttotalPoint/productStoryPoint)
                    setProductNoOfSprint(productnoOfSprint)
                    
                    const totalDevHour= producttotalPoint+ clienttotalPoint
                    setTotalDevHour(totalDevHour)
                    
                    let DevHourPrecentage;
                    totalDevHour?DevHourPrecentage=[(producttotalPoint / totalDevHour * 100).toFixed(2),(billedClient/totalDevHour*100).toFixed(2), (clienttotalPoint/ totalDevHour * 100).toFixed(2)]:DevHourPrecentage=[0,0,0];
                    setDevHourPrecentage(DevHourPrecentage);
                    const costBreakout = [buildSetupCost, producttotalPoint *productCost, billedClient*productCost,totalcost];
                    // console.log(costBreakout)
                    setCostBreakout(costBreakout);
                    const totalCostBreakout = costBreakout.reduce((partialSum, a) => partialSum + parseFloat(a), 0)-producttotalPoint *productCost;
                    setTotalCostBreakout (totalCostBreakout);
                    // console.log(totalCostBreakout)
                    let costBreakoutPrecentage = [0.00,0.00,0.00,0.00];
                    if (totalCostBreakout != 0) {
                        costBreakout.map((value, idx) => {
                            if (idx===1){
                                costBreakoutPrecentage[idx] = 0
                            }
                            else{let temp=(value/totalCostBreakout*100).toFixed(2)
                            costBreakoutPrecentage[idx] = temp;
                            // console.log(costBreakoutPrecentage[idx])
                        }
                        });
                    }
                    
                    // console.log(costBreakoutPrecentage);
                    setCostBreakoutPrecentage(costBreakoutPrecentage);

                    
                    setIsLoading(false);
                }).catch((error) => console.log(error));


        }
        fetchCustomCostData();

    }, []);


    useEffect(() => {
        const temptotalCost=SumofCoulmn(rows, 'Cost');
        settotalcost(temptotalCost);
        settotaleffort(SumofCoulmn(rows, 'Effort'));

        const costBreakout = [buildSetupCost, productTotalPoint*productCost,BilledToClient*productCost,temptotalCost];
        setCostBreakout(costBreakout);
        const totalCostBreakout = costBreakout.reduce((partialSum, a) => partialSum + parseFloat(a), 0)-productTotalPoint *productCost;
        setTotalCostBreakout (totalCostBreakout);
        // console.log(totalCostBreakout)
        let costBreakoutPrecentage = [0.00,0.00,0.00,0.00];
        if (totalCostBreakout != 0) {
            costBreakout.map((value, idx) => {
                if(idx===1){
                    costBreakoutPrecentage[idx] = 0;
                }
                else{let temp=(value/totalCostBreakout*100).toFixed(2)
                costBreakoutPrecentage[idx] = temp;
                // console.log(costBreakoutPrecentage[idx])
            }
            });
        }
        
        // console.log(costBreakoutPrecentage);
        setCostBreakoutPrecentage(costBreakoutPrecentage);

        
        if (rows.length > 0) {
            setUpdatedFeild({
                FTE1: rows[2].FTE, Comment1: rows[2].Comments, CostHrRate1: rows[2].CostHrRate,
                FTE2: rows[3].FTE, Comment2: rows[3].Comments, CostHrRate2: rows[3].CostHrRate,
                FTE3: rows[4].FTE, Comment3: rows[4].Comments, CostHrRate3: rows[4].CostHrRate,
                FTE4: rows[5].FTE, Comment4: rows[5].Comments, CostHrRate4: rows[5].CostHrRate,
                FTE5: rows[6].FTE, Comment5: rows[6].Comments, CostHrRate5: rows[6].CostHrRate,
                FTE6: rows[0].FTE, Comment6: rows[0].Comments, CostHrRate6: rows[0].CostHrRate,
                FTE7: rows[1].FTE, Comment7: rows[1].Comments, CostHrRate7: rows[1].CostHrRate,
                Weeks: weeks,
                EstimatedContengency:clientContengencyPrecentage,
                StoryPoints: clientStoryPoint,
                DataMigration:0,
                ProductContengency:productContengencyPrecentage,
                ProductStoryPoints:productStoryPoint,
                ProductCostPerStoryPoint:productCost
            })


        }

    }, [rows]);

    useEffect(()=>{
        if (rows.length > 0) {
            setUpdatedFeild({
                FTE1: rows[2].FTE, Comment1: rows[2].Comments, CostHrRate1: rows[2].CostHrRate,
                FTE2: rows[3].FTE, Comment2: rows[3].Comments, CostHrRate2: rows[3].CostHrRate,
                FTE3: rows[4].FTE, Comment3: rows[4].Comments, CostHrRate3: rows[4].CostHrRate,
                FTE4: rows[5].FTE, Comment4: rows[5].Comments, CostHrRate4: rows[5].CostHrRate,
                FTE5: rows[6].FTE, Comment5: rows[6].Comments, CostHrRate5: rows[6].CostHrRate,
                FTE6: rows[0].FTE, Comment6: rows[0].Comments, CostHrRate6: rows[0].CostHrRate,
                FTE7: rows[1].FTE, Comment7: rows[1].Comments, CostHrRate7: rows[1].CostHrRate,
                Weeks: weeks,
                EstimatedContengency:clientContengencyPrecentage,
                StoryPoints: clientStoryPoint,
                DataMigration:0,
                ProductContengency:productContengencyPrecentage,
                ProductStoryPoints:productStoryPoint,
                ProductCostPerStoryPoint:productCost
            })

        }
    },[clientContengencyPrecentage,clientStoryPoint,productContengencyPrecentage,productStoryPoint,productCost]);
    
    
    const handleCellChange = (newValue, rowIndex, columnIndex) => {
        setDirty();
        if (columnIndex === 1) {
            //changes in fte column
            setRows(rows.map((currRow, idx) => {
                if (idx !== rowIndex) return currRow;
                else {
                    const tempEffort = newValue * weeklyHour * weeks;
                    const tempCost = Math.round(tempEffort * currRow["CostHrRate"] / 10) * 10;
                    return ({ ...currRow, "FTE": newValue, "Effort": tempEffort, "Cost": tempCost });
                }

            }));
        }
        else if (columnIndex === 3) {
            //changes in cost hr Rate column
            setRows(rows.map((currRow, idx) => {
                if (idx !== rowIndex) return currRow;
                else {
                    const tempCost = Math.round(currRow["Effort"] * newValue / 10) * 10;
                    return ({ ...currRow, "CostHrRate": newValue, "Cost": tempCost });
                }

            }));
        }
        else if(rowIndex===11 && columnIndex===2){
            // changes in contengency precentage of client
            let tempPrecentage=newValue;
            setClientContengencyPrecentage(tempPrecentage)
            let tempContengency=Math.ceil(clientEstimatedHour*tempPrecentage/100)
            setClientEstimatedContengency(tempContengency)
            let temptotalPoint=tempContengency+clientEstimatedHour;
            setClientTotalPoint(temptotalPoint);
            let tempweek=0;
            let tempnoOfSprint=0;
           if(clientStoryPoint!=0){
            tempnoOfSprint=Math.ceil(temptotalPoint/clientStoryPoint);
            setClientNoOfSprint(tempnoOfSprint);
            tempweek=tempnoOfSprint*2;
            setWeeks(tempweek);
           } 
           else{
            setClientNoOfSprint(tempnoOfSprint);
            setWeeks(tempnoOfSprint*2);
           }
           const temptotalDevHour=productTotalPoint+temptotalPoint
           setTotalDevHour(temptotalDevHour)
           let tempDevHourPrecentage=[0,0,0]
           temptotalDevHour?tempDevHourPrecentage=[(productTotalPoint / temptotalDevHour * 100).toFixed(2),(BilledToClient/temptotalDevHour*100).toFixed(2), (temptotalPoint/ temptotalDevHour * 100).toFixed(2)]:tempDevHourPrecentage=[0,0,0];
           setDevHourPrecentage(tempDevHourPrecentage);
            
            // as the value of week changes the efforts in each row of the first table changes.
            setRows(rows.map((currRow) => {
                const tempeffort = currRow['FTE'] * weeklyHour * tempweek;
                const tempcost = Math.round(tempeffort * currRow['CostHrRate'] / 10) * 10;
                return ({ ...currRow, "Effort": tempeffort, "Cost": tempcost });
            }));
           
        }
        else if(rowIndex===13 && columnIndex===2){
            // changes in contengency precentage of product
            let tempPrecentage=newValue;
            setProductContengencyPrecentage(tempPrecentage)
            let tempContengency=Math.ceil(productEstimatedHour*tempPrecentage/100)
            setProductEstimatedContengency(tempContengency)
            let temptotalPoint=tempContengency+productEstimatedHour;
            setProductTotalPoint(temptotalPoint);
            const tempbilledClient=Math.ceil(pointsBilled*tempPrecentage/100)+pointsBilled
            setBilledToClient(tempbilledClient);
            let tempnoOfSprint=0;
           if(productStoryPoint!=0){
            tempnoOfSprint=Math.ceil(temptotalPoint/productStoryPoint);
            } 
           setProductNoOfSprint(tempnoOfSprint);
           
           const temptotalDevHour=clientTotalPoint+temptotalPoint
           setTotalDevHour(temptotalDevHour)
           let tempDevHourPrecentage=[0,0,0]
           temptotalDevHour?tempDevHourPrecentage=[(temptotalPoint / temptotalDevHour * 100).toFixed(2),(tempbilledClient/temptotalDevHour*100).toFixed(2), (clientTotalPoint/ temptotalDevHour * 100).toFixed(2)]:tempDevHourPrecentage=[0,0,0];
            setDevHourPrecentage(tempDevHourPrecentage);

           const tempcostBreakout = costBreakout
           tempcostBreakout[1]=temptotalPoint * productCost
           tempcostBreakout[2]=tempbilledClient*productCost
           setCostBreakout(tempcostBreakout);
           const totalCostBreakout = tempcostBreakout.reduce((partialSum, a) => partialSum + parseFloat(a), 0)-tempcostBreakout[1];
           setTotalCostBreakout (totalCostBreakout);
        //    console.log(totalCostBreakout)
           let costBreakoutPrecentage = [0.00,0.00,0.00,0.00];
           if (totalCostBreakout != 0) {
               tempcostBreakout.map((value, idx) => {
                   if(idx===1){
                       costBreakoutPrecentage[idx] = 0;
                   }
                   else{let temp=(value/totalCostBreakout*100).toFixed(2)
                   costBreakoutPrecentage[idx] = temp;
                //    console.log(costBreakoutPrecentage[idx])
                }
               });
           }
          
        //    console.log(costBreakoutPrecentage);
           setCostBreakoutPrecentage(costBreakoutPrecentage);
         
           
        }
        else if(rowIndex===11 && columnIndex===4){
            // changes in storypoint value of client
            let tempStoryPoint=newValue;
            setClientStoryPoint(tempStoryPoint);
           
            let tempweek=0;
            let tempnoOfSprint=0;
           if(tempStoryPoint!=0){
            tempnoOfSprint=Math.ceil(clientTotalPoint/tempStoryPoint);
            setClientNoOfSprint(tempnoOfSprint);
            tempweek=tempnoOfSprint*2;
            setWeeks(tempweek);
           } 
           else{
            setClientNoOfSprint(tempnoOfSprint);
            setWeeks(tempnoOfSprint*2);
           }
            
            // as the value of week changes the efforts in each row of the first table changes.
            setRows(rows.map((currRow) => {
                const tempeffort = currRow['FTE'] * weeklyHour * tempweek;
                const tempcost = Math.round(tempeffort * currRow['CostHrRate'] / 10) * 10;
                return ({ ...currRow, "Effort": tempeffort, "Cost": tempcost });
            }));
           
        }
        else if(rowIndex===13 && columnIndex===4){
            // changes in storypoint value of product
            let tempStoryPoint=newValue;
            setProductStoryPoint(tempStoryPoint);
           
            let tempnoOfSprint=0;
           if(tempStoryPoint!=0){
            tempnoOfSprint=Math.ceil(productTotalPoint/tempStoryPoint);
           }
            setProductNoOfSprint(tempnoOfSprint);
          
        }
        else if(rowIndex===13 && columnIndex===6){
            // changes in cost per storypoint of product
            let tempCost=newValue;
            setProductCost(tempCost);
            const tempcostBreakout = costBreakout
            tempcostBreakout[1]=tempCost*productTotalPoint
            tempcostBreakout[2]=tempCost*BilledToClient
            setCostBreakout(tempcostBreakout);
            const totalCostBreakout = tempcostBreakout.reduce((partialSum, a) => partialSum + parseFloat(a), 0)-tempcostBreakout[1];
            setTotalCostBreakout (totalCostBreakout);
            // console.log(totalCostBreakout)
            let costBreakoutPrecentage = [0.00,0.00,0.00,0.00];
            if (totalCostBreakout != 0) {
                tempcostBreakout.map((value, idx) => {
                    if(idx===1){
                        costBreakoutPrecentage[idx] = 0;
                    }
                    else{let temp=(value/totalCostBreakout*100).toFixed(2)
                    costBreakoutPrecentage[idx] = temp;
                    // console.log(costBreakoutPrecentage[idx])
                }
                });
            }
            
            // console.log(costBreakoutPrecentage);
            setCostBreakoutPrecentage(costBreakoutPrecentage);
          
        }
        // else if(rowIndex==15 && columnIndex==2){
        //     // change in data migration field
        //     let tempdataMigration=newValue;
        //     setDataMigration(tempdataMigration);
        //     const totalDevHour= clientTotalPoint + productTotalPoint + tempdataMigration+ BilledToClient
        //     setTotalDevHour(totalDevHour)
        //     let DevHourPrecentage;
        //     totalDevHour?DevHourPrecentage=[(productTotalPoint / totalDevHour * 100).toFixed(2),(BilledToClient/totalDevHour*100).toFixed(2), (clientTotalPoint/ totalDevHour * 100).toFixed(2), (tempdataMigration / totalDevHour * 100).toFixed(2)]:DevHourPrecentage=[0,0,0,0];
        //     setDevHourPrecentage(DevHourPrecentage);
        //     const tempcostBreakout = costBreakout
        //     tempcostBreakout[4]=Math.floor((totalcost * DevHourPrecentage[3] )/ 100)
        //     // console.log(tempcostBreakout)
        //     setCostBreakout(tempcostBreakout);
        //     const totalCostBreakout = tempcostBreakout.reduce((partialSum, a) => partialSum + parseFloat(a), 0)-tempcostBreakout[1];
        //     setTotalCostBreakout (totalCostBreakout);
        //     // console.log(totalCostBreakout)
        //     let costBreakoutPrecentage = [0.00,0.00,0.00,0.00,0.00];
        //     if (totalCostBreakout != 0) {
        //         costBreakout.map((value, idx) => {
        //             if (idx===1){
        //                 costBreakoutPrecentage[idx] = 0
        //             }
        //             else{let temp=(value/totalCostBreakout*100).toFixed(2)
        //             costBreakoutPrecentage[idx] = temp;
        //             // console.log(costBreakoutPrecentage[idx])
        //         }
        //         });
        //     }
            
        //     // console.log(costBreakoutPrecentage);
        //     setCostBreakoutPrecentage(costBreakoutPrecentage);


        // }
        else {
            setRows(rows.map((currRow, idx) => {
                //changes in comment column
                if (idx !== rowIndex) return currRow;
                else {
                    return ({ ...currRow, "Comments": newValue });
                }

            }));
        }
    };

    const [openNotification, setOpenNotification] = useState(false);
    const handleSave = async (e) => {
        e.preventDefault();
        setOpenNotification(true);
        // validation check to publish
        // updatedFeild.FTE6?setCanPublish({...canPublish, CustomTeamCostTab:"True"}):setCanPublish({...canPublish, CustomTeamCostTab:"False"});
        await axiosInstance.post(updateCustomTeamAPI, { ...updatedFeild, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
        setNotDirty();
    }

    return (

        <>{isLoading ? <Spinnerprogress /> :
            <>
                <Notification openNotification={openNotification} setOpenNotification={setOpenNotification} message={" Saved Successfully"}/>
                <EditableTable rows={rows} handleCellChange={handleCellChange} totalcost={totalcost} totaleffort={totaleffort} weeks={weeks} view={view}  weekhide={true}/>
                <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '20px', visibility: view ? 'hidden' : 'visible' }} onClick={handleSave}>
                    Save
                </Button>
                <Box sx={{ marginTop: '30px', marginBottom: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow className='table__header'>
                                    <TableCell colSpan={2} style={{textAlign:"center",fontSize:"15px"}}>Client Delivery</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{fontSize:"13px"}} >Intermediate calculations</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell  sx={{width: '70%'}}>Estimated Points</TableCell>
                                    <TableCell className="text__right">{clientEstimatedHour}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Contingency Percentage</TableCell>
                                    <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={clientContengencyPrecentage} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 11, 2) // to change contigency precentage of client
                                            }
                                        /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Estimated Contingency</TableCell>
                                    <TableCell className="text__right">{clientEstimatedContengency}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total Story Points</TableCell>
                                    <TableCell className="text__right">{clientTotalPoint}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} style={{textAlign:"center",fontSize:"15px"}} className='table__header'>Client Delivery</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                    <TableCell className="text__right" sx={{width: '50%'}}>Story points/Team</TableCell>
                                    <TableCell className="text__right">Sprints needed</TableCell>
                                    <TableCell className="text__right">Week Duration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={clientStoryPoint} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 11, 4) // to change story point value of client
                                            }
                                        /></TableCell>
                                    <TableCell className="text__right">{clientNoOfSprint}</TableCell>
                                    <TableCell className="text__right">{weeks}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ marginTop: '30px', marginBottom: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} style={{textAlign:"center",fontSize:"15px"}}  className='table__header'>Product Team</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{fontSize:"13px"}}>Intermediate calculations</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell  sx={{width: '70%'}}>Estimated Points</TableCell>
                                    <TableCell className="text__right">{productEstimatedHour}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Contingency Percentage</TableCell>
                                    <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={productContengencyPrecentage} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 13, 2) // to change contigency precentage of product
                                            }
                                        /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Estimated Contingency</TableCell>
                                    <TableCell className="text__right">{productEstimatedContengency}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total Story Points</TableCell>
                                    <TableCell className="text__right">{productTotalPoint}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell colSpan={2}></TableCell>
                                    
                                </TableRow> */}
                                <TableRow>
                                    <TableCell> Product points Billed to Client</TableCell>
                                    <TableCell className="text__right">{BilledToClient}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} style={{textAlign:"center",fontSize:"15px"}} className='table__header'>Product Team</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                    <TableCell className="text__right" sx={{width: '33.99%'}}>Story points/Team</TableCell>
                                    <TableCell className="text__right">Sprints needed</TableCell>
                                    <TableCell className="text__right">Week Duration</TableCell>
                                    <TableCell className="text__right" sx={{width: '33.99%'}}>Cost Per story points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={productStoryPoint} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 13, 4) // to change story point value of product
                                            }
                                        /></TableCell>
                                    <TableCell className="text__right">{productNoOfSprint}</TableCell>
                                    <TableCell className="text__right">{2*productNoOfSprint}</TableCell>
                                    <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={productCost} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 13, 6) // to change cost  per story point of product
                                            }
                                        /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead className='table__header'>
                            <TableRow>
                                <TableCell colSpan={2} style={{textAlign: 'center'}}>TOTAL OVERALL COST</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Customization Cost</TableCell>
                                <TableCell className="text__right">{currencyFormatter(totalcost)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> Schedule - in Wks</TableCell>
                                <TableCell className="text__right">{weeks}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer sx={{ marginTop: '25px', marginBottom: '25px' }}>
                    <Table>
                        <TableHead className='table__header'>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell className="text__right" sx={{width: '25%'}}>Story Points</TableCell>
                                <TableCell className="text__right">Percentage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Product Roadmap</TableCell>
                                <TableCell className="text__right">{productTotalPoint}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(DevHourPrecentage[0])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Product points Billed to Client</TableCell>
                                <TableCell className="text__right">{BilledToClient}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(DevHourPrecentage[1])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> Client Extensions</TableCell>
                                <TableCell className="text__right">{clientTotalPoint}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(DevHourPrecentage[2])}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Data migration</TableCell>
                                <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={dataMigration} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 15, 2) // to change contigency value
                                            }
                                        /></TableCell>
                                <TableCell className="text__right">{precentageFormatter(DevHourPrecentage[3])}</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell className="text__right">{totalDevHour}</TableCell>
                                <TableCell className="text__right">{precentageFormatter((totalDevHour/totalDevHour*100).toFixed(2))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className='table__header'>                              
                                <TableCell colSpan={3} style={{textAlign: 'center'}}>ROUGH COST BREAKOUT</TableCell>                                                            
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell className="text__right">Cost</TableCell>
                                <TableCell className="text__right">Percentage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Base Product Build/Configure</TableCell>
                                <TableCell className="text__right">{currencyFormatter(costBreakout[0])}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(costBreakoutPrecentage[0])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Roadmap items needed</TableCell>
                                <TableCell className="text__right">{currencyFormatter(costBreakout[1])}</TableCell>
                                <TableCell className="text__right">{}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Roadmap items needed (Billed to client)</TableCell>
                                <TableCell className="text__right">{currencyFormatter(costBreakout[2])}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(costBreakoutPrecentage[2])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Client Extensions</TableCell>
                                <TableCell className="text__right">{currencyFormatter(costBreakout[3])}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(costBreakoutPrecentage[3])}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Data migration</TableCell>
                                <TableCell className="text__right">{currencyFormatter(costBreakout[4])}</TableCell>
                                <TableCell className="text__right">{precentageFormatter(costBreakoutPrecentage[4])}</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell className="text__right">{currencyFormatter(totalcostBreakout)}</TableCell>
                                <TableCell className="text__right">{precentageFormatter((Math.round(costBreakoutPrecentage.reduce((partialSum, a) => partialSum + parseFloat(a), 0))).toFixed(2))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        }
        </>

    );
};

export default ClientCustomCost;