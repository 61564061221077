import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { checkStartSpecialChar } from '../tabs/Constfunctions';
import InputAdornment from '@mui/material/InputAdornment';

export const Modal = ({ open, closeModal, onSubmit, defaultValue }) => {

  const initial = {
    Id: 0,
    FeatureName: "",
    FeatureDescription: "",
    Dependency: "",
    DependencyFeatureName: "",
    Phase: "",
    SpecificRequirementRPF: "",
    Fixed_Custom: "",
    DevOwner: "Client Delivery",
    ProductManager: "",
    StoryPoints: 0,
    BillToClient: 0,
    EstimateConfidence: "",
    EstimateBy: "",
    Timeline: "",
    Ticket_URL: "",
    Px_FR_Sheet: "",
    Assumptions: ""
  }

  const [formState, setFormState] = useState(initial);
  const [formError, setFormError] = useState({
    FeatureName: false,
    FeatureDescription: false,
    Dependency: false,
    DependencyFeatureName: false,
    Phase: false,
    SpecificRequirementRPF: false,
    Fixed_Custom: false,
    DevOwner: false,
    ProductManager: false,
    StoryPoints: false,
    BillToClient: false,
    EstimateConfidence: false,
    EstimateBy: false,
    Timeline: false,
    Ticket_URL: false,
    Px_FR_Sheet: false,
    Assumptions: false
  })

  useEffect(() => {
    const value = defaultValue || initial;
    setFormState(value);
    setFormError({ ...formError, FeatureName: false, StoryPoints: false });
  }, [open]);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    if (e.target.value !== '') {
      setFormError({ ...formError, [e.target.name]: false });
    }
    // disablAddBtn();
  };

  const onBlurValidation = (e) => {
    if (e.target.value === '') {
      setFormError({ ...formError, [e.target.name]: true });
    }
  }

  // const disablAddBtn = useMemo(
  //   () => formState.FeatureName !== 0 || formState.dependency !== 0 ||
  //   formState.FeatureDescription.length === 0 || formState.DependencyFeatureName.length === 0||
  //   formState.Phase.length === 0 || formState.SpecificRequirementRPF.length === 0 ||
  //   formState.Fixed_Custom.length === 0 || formState.DevOwner.length === 0 ||
  //   formState.ProductManager.length === 0 || formState.StoryPoints.length === 0 ||
  //   formState.EstimateConfidence.length === 0 || formState.EstimateBy.length === 0 ||
  //   formState.Timeline.length === 0 || formState.Ticket_URL.length === 0 ||
  //   formState.Px_FR_Sheet.length === 0 || formState.Assumptions.length === 0

  //   ,   
  //   [formState]
  // )

  // Validation on Save btn
  const disablAddBtn = useMemo(
    () => formState.FeatureName === '' || parseInt(formState.StoryPoints) < 0 || formState.StoryPoints === ''
    ,
    [formState]
  )
  // console.log(disablAddBtn());

  return (
    <React.Fragment>
      <Dialog sx={{ width: '100%' }}
        open={open}
        onClose={closeModal}
        PaperProps={{
          component: 'form',
          style: {
            maxWidth: '800px',
          },
          onSubmit: (event) => {
            event.preventDefault();
            onSubmit(formState);
            closeModal();
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: '700px',
              width: '100%', display: 'grid', gridTemplateColumns: 'auto auto auto'
            }}>
            <TextField
              sx={{ m: 1 }}
              id="FeatureName"
              label="Feature Name"
              variant="filled"
              name="FeatureName"
              onChange={handleChange}
              value={formState.FeatureName}
              error={formError.FeatureName}
              onBlur={onBlurValidation}
              onKeyDown={checkStartSpecialChar}
              required
            />


            <TextField sx={{ m: 1, gridColumn: '2 / span 2' }}
              id="FeatureDescription"
              variant="filled"
              label="Description"
              multiline
              maxRows={2}
              name="FeatureDescription"
              onChange={handleChange}
              value={formState.FeatureDescription}
              error={formError.FeatureDescription}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <FormControl error={formError.Dependency} variant="filled" sx={{ m: 1 }}>
              <InputLabel id="dependency-label">Dependency? (Y/N)</InputLabel>
              <Select
                labelId="dependency-label"
                id="Dependency"
                name="Dependency"
                onChange={handleChange}
                value={formState.Dependency}
                label="Dependency? (Y/N)"
              // onBlur={onBlurValidation}
              >
                <MenuItem value='Y'>Y</MenuItem>
                <MenuItem value='N'>N</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ m: 1 }}
              id="DependencyFeatureName"
              label="Dependency (Feature Name)"
              variant="filled"
              name="DependencyFeatureName"
              onChange={handleChange}
              value={formState.DependencyFeatureName}
              error={formError.DependencyFeatureName}
              onKeyDown={checkStartSpecialChar}
            //  onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="Phase"
              label="Phase"
              variant="filled"
              name="Phase"
              onChange={handleChange}
              value={formState.Phase}
              error={formError.Phase}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <FormControl error={formError.SpecificRequirementRPF} variant="filled" sx={{ m: 1 }}>
              <InputLabel id="specificrequirement-label">Specific Requirement in RFP?</InputLabel>
              <Select
                labelId="specificrequirement-label"
                id="SpecificRequirementRPF"
                name="SpecificRequirementRPF"
                onChange={handleChange}
                value={formState.SpecificRequirementRPF}
                label="Specific Requirement in RFP?"
              // onBlur={onBlurValidation}
              >
                <MenuItem value='Y'>Y</MenuItem>
                <MenuItem value='N'>N</MenuItem>
              </Select>
            </FormControl>

            <FormControl error={formError.Fixed_Custom} variant="filled" sx={{ m: 1 }}>
              <InputLabel id="fixedcustom-label">Fixed/Custom</InputLabel>
              <Select
                labelId="fixedcustom-label"
                id="Fixed_Custom"
                name="Fixed_Custom"
                onChange={handleChange}
                value={formState.Fixed_Custom}
                label="fixedcustom-label"
              // onBlur={onBlurValidation}
              >
                <MenuItem value='Fixed'>Fixed</MenuItem>
                <MenuItem value='Custom'>Custom</MenuItem>
              </Select>
            </FormControl>

            <FormControl error={formError.DevOwner} variant="filled" sx={{ m: 1 }}>
              <InputLabel id="fixedcustom-label">Development Owner</InputLabel>
              <Select
                labelId="fixedcustom-label"
                id="DevOwner"
                name="DevOwner"
                onChange={handleChange}
                value={formState.DevOwner}
                label="Specific Requirement in RFP? (Y/N)"
              // onBlur={onBlurValidation}
              >
                <MenuItem value='Client Delivery'>Client Delivery</MenuItem>
                <MenuItem value='Product'>Product</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ m: 1 }}
              id="ProductManager"
              label="Product Manager"
              variant="filled"
              name="ProductManager"
              onChange={handleChange}
              value={formState.ProductManager}
              error={formError.ProductManager}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="StoryPoints"
              label="Story Points"
              variant="filled"
              type='number'
              name="StoryPoints"
              onChange={handleChange}
              value={formState.StoryPoints}
              error={formError.StoryPoints}
              onBlur={onBlurValidation}
              required
            />

            <TextField
              sx={{ m: 1 }}
              id="BillToClient"
              label="Product points Billed to Client"
              variant="filled"
              type='number'
              name="BillToClient"
              onChange={handleChange}
              value={formState.DevOwner === 'Client Delivery' ? 0 : formState.BillToClient}
              error={formError.BillToClient}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled={formState.DevOwner === 'Client Delivery'}
            // onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="EstimateConfidence"
              label="Estimate Confidence Score"
              variant="filled"
              name="EstimateConfidence"
              onChange={handleChange}
              value={formState.EstimateConfidence}
              error={formError.EstimateConfidence}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="EstimateBy"
              label="Estimated By"
              variant="filled"
              name="EstimateBy"
              onChange={handleChange}
              value={formState.EstimateBy}
              error={formError.EstimateBy}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="Timeline"
              label="Timeline"
              variant="filled"
              name="Timeline"
              onChange={handleChange}
              value={formState.Timeline}
              error={formError.Timeline}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            <TextField
              sx={{ m: 1 }}
              id="Ticket_URL"
              label="URL to ADO Ticket"
              variant="filled"
              name="Ticket_URL"
              onChange={handleChange}
              value={formState.Ticket_URL}
              error={formError.Ticket_URL}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

            {/* <TextField
              sx={{ m: 1 }}
              id="Px_FR_Sheet"
              label="In Px F&R sheet?"
              variant="filled"
              name="Px_FR_Sheet"
              onChange={handleChange}
              value={formState.Px_FR_Sheet}
              error={formError.Px_FR_Sheet}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            /> */}

            <TextField
              sx={{ m: 1, gridColumn: '2 / span 2' }}
              // sx={{ m: 1 }}
              id="Assumptions"
              label="Comments/Assumptions"
              variant="filled"
              name="Assumptions"
              onChange={handleChange}
              value={formState.Assumptions}
              error={formError.Assumptions}
              onKeyDown={checkStartSpecialChar}
            // onBlur={onBlurValidation}
            />

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color='secondary'>Cancel</Button>
          <Button type="submit" color='secondary' disabled={disablAddBtn}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}