import React from "react";
// import Table from "ui-component/Table.js";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { TableFooter } from "@mui/material";
import { precentageFormatter } from '../tabs/Constfunctions'
// import { number } from "prop-types";

export const ClientTable = ({ view, rows, deleteRow, editRow, clientDelivery, productRoadmap, billedToClient }) => {

  return (

    <TableContainer sx={{ marginTop: '20px', maxHeight: 450 }}>
      <Table stickyHeader sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead className="client__custom__tr">
          <TableRow>
            <TableCell sx={{ display: view ? 'none' : 'table-cell' }}></TableCell>
            <TableCell sx={{ minWidth: 140 }}>Feature Name</TableCell>
            <TableCell className="description__cell">Description</TableCell>
            <TableCell>Dependency? (Y/N)</TableCell>
            <TableCell>Dependency (Feature Name)</TableCell>
            <TableCell>Phase</TableCell>
            <TableCell>Specific Requirement in RFP? (Y/N)</TableCell>
            <TableCell>Fixed /Custom</TableCell>
            <TableCell>Development Owner</TableCell>
            <TableCell>Product Manager</TableCell>
            <TableCell className='text__right'>Story Points</TableCell>
            <TableCell sx={{ minWidth: 150 }} className='text__right'>% Billed to Client <br />(Product)</TableCell>
            <TableCell className='text__right'>Estimate Confidence Score</TableCell>
            <TableCell>Estimated By</TableCell>
            <TableCell>Timeline</TableCell>
            <TableCell sx={{ minWidth: 240 }}>URL to ADO Ticket</TableCell>
            {/* <TableCell>In Px F&R sheet?</TableCell> */}
            <TableCell className="description__cell">Comments/Assumptions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {

            return (
              <TableRow key={idx}>
                <TableCell sx={{ display: view ? 'none' : 'table-cell' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    <EditIcon onClick={() => editRow(idx, row.ID)} sx={{ cursor: 'pointer' }} />
                    <DeleteIcon onClick={() => deleteRow(row.ID)} sx={{ cursor: 'pointer' }} />
                  </Box>
                </TableCell>
                <TableCell>{row.FeatureName}</TableCell>
                <TableCell>{row.FeatureDescription}</TableCell>
                <TableCell>{row.Dependency}</TableCell>
                <TableCell >{row.DependencyFeatureName}</TableCell>
                <TableCell >{row.Phase}</TableCell>
                <TableCell>{row.SpecificRequirementRPF}</TableCell>
                <TableCell>{row.Fixed_Custom}</TableCell>
                <TableCell>{row.DevOwner}</TableCell>
                <TableCell>{row.ProductManager}</TableCell>
                <TableCell className='text__right'>{row.StoryPoints}</TableCell>
                <TableCell className='text__right'>{row.DevOwner === 'Product' ? precentageFormatter(row.BillToClient):''}</TableCell>
                <TableCell className='text__right'>{row.EstimateConfidence}</TableCell>
                <TableCell>{row.EstimateBy}</TableCell>
                <TableCell>{row.Timeline}</TableCell>
                <TableCell>{row.Ticket_URL}</TableCell>
                {/* <TableCell>{row.Px_FR_Sheet}</TableCell> */}
                <TableCell>{row.Assumptions}</TableCell>

              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell colSpan={8} ></TableCell>
            <TableCell >Total</TableCell>
            <TableCell className='text__right'>{productRoadmap + clientDelivery}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} ></TableCell>
            <TableCell colSpan={2} align="center">Product Roadmap</TableCell>
            <TableCell className='text__right'>{productRoadmap}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} ></TableCell>
            <TableCell colSpan={2} align="center">Client Delivery</TableCell>
            <TableCell className='text__right'>{clientDelivery}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} ></TableCell>
            <TableCell colSpan={2} align="center">Product points Billed to Client</TableCell>
            <TableCell className='text__right'>{Math.ceil(billedToClient)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};