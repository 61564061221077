import  { useEffect, useState } from 'react'
// import {unstable_usePrompt} from 'react-router-dom'\
// import { unstable_usePrompt} from 'react-router-dom';
const useUnsaveChanges = (message = 'Please save your changes before leaving a tab') => {
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        window.onbeforeunload = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
        }
    }, [isDirty]);

    // const routerPrompt = unstable_usePrompt({when:isDirty, message: message});
  
    return [isDirty, () => setIsDirty(true), () => setIsDirty(false)]
}

export default useUnsaveChanges;