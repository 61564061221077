import React, { useState, useEffect, useMemo  } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import '../style/publishTable.css'
import {publishEstimation} from 'api/api';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import { FormHelperText } from '@mui/material';
import axiosInstance from "interceptors/axiosInstance";
// import Notification from "../../../ui-component/notification/notification";


export const PublishModal = ({ open, closeModal,canPublish,ClientId, ProjectId, EstimationId, Version}) => {
  
  useEffect(() => {
      setcomment("");
  }, [open]);

  const [comment, setcomment] = useState("");
  let navigate = useNavigate(); 

  const handleChange = (e) => {
    setcomment(e.target.value);
  };

  // const [publishNotification, setPublishNotification] = useState(false);
  const onPublish=async(comment)=>{
    await axiosInstance.post(publishEstimation,{ClientId:ClientId,ProjectId:ProjectId,EstimationId:EstimationId,Version:Version,EstimateComment:comment}).catch((error)=>console.log(error));
    let path = `/estimation?ClientId=${ClientId}&ProjectId=${ProjectId}`; 
    navigate(path);
    // setPublishNotification(true);
    // console.log(EstimationId,Version,comment);
  }

  const disablePublishBtn =useMemo(
    () => 
      // console.log(canPublish.BuildTeamcostTab,canPublish.CustomTeamCostTab);
      !(canPublish.BuildTeamcostTab==="True" && canPublish.CustomTeamCostTab==="True" && canPublish.AnnualSupportCostTab==="True" && canPublish.ClientCustomTab==="True" && canPublish.DetailedScheduleTab==="True")
    ,   
    [canPublish]
  )
    // console.log(disablePublishBtn)  

  return (
    <React.Fragment>
        <Dialog sx={{ width: '100%' }}
        open={open}
        onClose={closeModal}
        PaperProps={{
          component: 'form',
          style: {
            maxWidth: '500px',
          },
          onSubmit: (event) => {
            event.preventDefault();
            console.log(comment);
            onPublish(comment);
            closeModal();
          },
        }}
      >
        <DialogTitle><Typography variant="h3" >Review details</Typography></DialogTitle>
        <DialogContent>
          <Box
            sx={{ width: '100%', display: 'grid', minWidth: '400px'}}>
            <Box>
                <Table className="publish-list-table" sx={{marginBottom:'20px'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                              <Typography variant="h5"> Detailed Schedule Tab </Typography>
                            </TableCell>
                            <TableCell>
                            <Typography variant="h5" color={canPublish["DetailedScheduleTab"]==='True' ? 'success.dark' : 'red'}>{canPublish["DetailedScheduleTab"]==='True'?"Completed":"Incomplete"}
                            </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                              <Typography variant="h5"> Client Custom Tab </Typography>
                            </TableCell>
                            <TableCell>
                            <Typography variant="h5" color={canPublish["ClientCustomTab"]==='True' ? 'success.dark' : 'red'}>{canPublish["ClientCustomTab"]==='True'?"Completed":"Incomplete"}
                            </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                              <Typography variant="h5"> Team & Cost Tab </Typography>
                            </TableCell>
                            <TableCell>
                            <Typography variant="h5" color={canPublish["BuildTeamcostTab"]==="True" && canPublish["CustomTeamCostTab"] ==="True" ? 'success.dark' : 'red'}>{canPublish["BuildTeamcostTab"]==="True" && canPublish["CustomTeamCostTab"]==="True"?"Completed":"Incomplete"}
                            </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                              <Typography variant="h5"> Annual Support Tab </Typography>
                            </TableCell>
                            <TableCell>
                            <Typography variant="h5" color={canPublish["AnnualSupportCostTab"]==='True' ? 'success.dark' : 'red'}>{canPublish["AnnualSupportCostTab"]==='True'?"Completed":"Incomplete"}
                            </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
              </Box>
           
            <TextField
              sx={{ m: 1,visibility: disablePublishBtn ? 'hidden' : 'visible',display:disablePublishBtn?'none':'inline-flex' }}
              id="comment"
              label="Revision Comment"
              variant="filled"
              required
              multiline
              maxRows={3}
              name="comment"
              onChange={handleChange}
              value={comment} />

            </Box>
            <FormHelperText sx={{color:"red",visibility: disablePublishBtn ?'visible': 'hidden',display:disablePublishBtn?'block':'none'}}>* Required inputs are incomplete hence publish is disabled, please go and review your data once again.</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color='secondary' >Cancel</Button>
          <Button type="submit" color='secondary' disabled={disablePublishBtn}>Publish</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}