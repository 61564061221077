import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { formatDate } from "../tabs/Constfunctions";

export const TableComponent = ({ view, rows, columns, deleteRow, editRow }) => {
  return (

    <TableContainer sx={{ marginTop: '20px', maxHeight: 400 }} >
      <Table stickyHeader sx={{ minWidth: '100%' }} aria-label="simple table">
        <TableHead  className='detail__tr'>
          <TableRow>
            {columns.map((col, idx) => {
              return (

                <TableCell key={idx} sx={{ display: view && idx == 0 ? 'none' : 'table-cell' }}>{col}</TableCell>);
            })}

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {

            return (
              <TableRow key={idx}>
                <TableCell sx={{ display: view ? 'none' : 'table-cell' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    <EditIcon onClick={() => editRow(idx)} style={{ cursor: 'pointer' }} />
                    <DeleteIcon onClick={() => deleteRow(columns[1] === 'Assumption' ? row.AssumptionId : row.QuestionId)} style={{ cursor: 'pointer' }} />
                  </Box>
                </TableCell>

                <TableCell>{columns[1] === 'Assumption' ? row.Assumption : row.Question}</TableCell>
                <TableCell>{row.UpdatedBy}</TableCell>
                <TableCell >{formatDate(row.Updated)}</TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </Table>

    </TableContainer>
  );
};