import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// routes
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import Version from 'views/versions';
import Estimation from 'views/estimations/Estimation';
import Dashboard from 'views/dashboard/Default';
import Unauthorized from 'ui-component/unathorize-page/Unathorize';

const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'home',
        element: <Dashboard />
      },

      {
        path: 'estimation',
        element: <Estimation />
      },
      {
        path: 'sample-page',
        element: <SamplePage />
      },
      {
        path: 'estimation/edit',
        element: <Version view={false} />
      },

      {
        path: 'estimation/view',
        element: <Version view={true} />
      }
    ]
  };
  const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: 'login',
        element: <AuthLogin3 />
      },
      // {
      //   path: '/pages/register/register3',
      //   element: <AuthRegister3 />
      // },
      {
        path: '/401',
        element: <Unauthorized />
      }
    ]
  };
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
