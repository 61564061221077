// import dashboard from './dashboard';
import pages from './pages';
// import utilities from './utilities';
import home from './Home';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, pages]
  // items: [dashboard, pages, utilities, other]
};

export default menuItems;
