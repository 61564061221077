import * as React from 'react';
import { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { TableComponent } from "../../component/TableComponent";
import { AssumptionQuestionModal } from "../../component/AssumptionQuestionModal";
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import axiosInstance from 'interceptors/axiosInstance';

import { deleteAssumptionAPI, getAssumptionDataAPI, InsertUpdateAssumptionAPI } from 'api/api';

function Assumption({ view, initialRows, ClientId, ProjectId, EstimationId, Version }) {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(initialRows);

  const columns = ['', 'Assumption', 'Added BY', 'Added On'];
  const [rowToEdit, setRowToEdit] = useState(null);
  const [refreshRows, setRefreshRows] = useState(0);

  const handleDeleteRow = async (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosInstance.delete(deleteAssumptionAPI, { data: { AssumptionId: id, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version } }).catch((error) => console.log(error));
        setRows(rows.filter((r) => r.AssumptionId !== id));
        setRefreshRows(refreshRows + 1);
      }
    });
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setOpen(true);

  };

  const handleSubmit = async (newRow) => {

    if (rowToEdit === null) {
      await axiosInstance.post(InsertUpdateAssumptionAPI, { ...newRow, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
      setRows([...rows, newRow]);
    }
    else {
      await axiosInstance.post(InsertUpdateAssumptionAPI, { ...newRow, ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
      setRows(
        rows.map((currRow, idx) => {
          if (idx !== rowToEdit) return currRow;
          return newRow;
        })
      );
    }
    setRefreshRows(refreshRows + 1);



  };

  useEffect(() => {

    const fetchRows = async () => {
      await axiosInstance.get(`${getAssumptionDataAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).then((response) => {
        setRows([...response.data]);
      }).catch((error) => console.log(error));
    }
    fetchRows();
  }, [refreshRows]);


  return (
    <Box>
      <Button style={{ visibility: view ? 'hidden' : 'visible' }} size="1.3rem" type="button" variant="contained" color="secondary" onClick={() => setOpen(true)}>
      <><AddIcon />Add</>
      </Button >
      <TableComponent view={view} rows={rows} columns={columns} deleteRow={handleDeleteRow} editRow={handleEditRow} />

      <AssumptionQuestionModal column1={'assumption'} open={open}
        closeModal={() => {
          setOpen(false);
          setRowToEdit(null);
        }}
        onSubmit={handleSubmit}
        defaultValue={rowToEdit !== null && rows[rowToEdit]} />

    </Box>
  );
}

export default Assumption;