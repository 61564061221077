import React, { useEffect, useState } from 'react'
import { InputLabel, Stack, Select, FormControl, MenuItem, Box, Button, Grid, } from '@mui/material'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditableTableCell from '../../component/EditableTableCell';
import { Item } from '../Constfunctions';
import { SumofCoulmn, currencyFormatter } from '../Constfunctions';
import '../../style/tablestyle.css';
import { getAnnualSupportDataAPI, getImplementationTypeValuesAPI, updateAnnualSupportCostAPI } from 'api/api';
import axiosInstance from 'interceptors/axiosInstance';
import Spinnerprogress from 'ui-component/circular-progress/Spinnerprogress';


const AnualSupportCost = ({ view, setTab, EstimationId, Version }) => {

  const [type, setType] = useState('');
  const [row, setRows] = useState([]);
  const [week,setWeek] =useState(0);
  const [column, setColumn] = useState([]);
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const getInitialState = (type) => {
    var initialType = '';
    type.map(item => {
      if (item.Select) {
        initialType = item.Value;
        }
    });
    
    return initialType;
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchInitialData = async () => {

      // api for dropdown items
      const implementationTypeResponse = await axiosInstance.get(`${getImplementationTypeValuesAPI}?EstimationId=${EstimationId}`).catch((error) => console.log(error));
      setItems([...implementationTypeResponse.data]);

      setType(getInitialState(implementationTypeResponse.data));
      // api for data rows
      await axiosInstance.get(`${getAnnualSupportDataAPI}?EstimationId=${EstimationId}&Version=${Version}`).then((response)=>{
        const initialRow=response.data;
        // console.log(initialRow)
        const finalRow=[initialRow[4],initialRow[5],initialRow[0],initialRow[1],initialRow[2],initialRow[3]];
        // console.log(finalRow)  
        setRows(finalRow);
        setWeek(initialRow[0].DurationOfSupport)
        setColumn([...columns]);
        setIsLoading(false); 
        
      }).catch((error) => console.log(error))
      
      
        
    }

    fetchInitialData();
    
    
    
  }, []);


  const handleTypeChange = async (e) => {
    e.preventDefault();
    setType(e.target.value);
      await axiosInstance.post(updateAnnualSupportCostAPI, {ImplementationType : e.target.value, EstimationID: EstimationId, Version: Version,DurationOfSupport:week}).then((response)=>{
      const initialRow=response.data;
      const finalRow=[initialRow[4],initialRow[5],initialRow[0],initialRow[1],initialRow[2],initialRow[3]]
      setRows(finalRow);
    }).catch((error) => console.log(error));

  }

  const handleCellChange = async(newValue) => {
    setWeek(newValue)
    await axiosInstance.post(updateAnnualSupportCostAPI, {ImplementationType : type, EstimationID: EstimationId, Version: Version,DurationOfSupport:newValue}).then((response)=>{
      const initialRow=response.data;
      const finalRow=[initialRow[4],initialRow[5],initialRow[0],initialRow[1],initialRow[2],initialRow[3]]
      setRows(finalRow);
    }).catch((error) => console.log(error));
    
  }

  const handleNext = (e) => {
    e.preventDefault();
    setTab('Assumptions-&-Questions');
  }
  const handleBack = (e) => {
    e.preventDefault();
    setTab('Team-&-Cost');
  }
  

  return (
    <>
    { isLoading ? <Spinnerprogress /> : 
        <Box
        noValidate
        component="form"
        sx={{
          width: '100%'
  
        }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid xs={3}>
            <Item><h4>Implementation Type</h4></Item>
          </Grid>
          <Grid xs={3}>
            <Item>
              <FormControl disabled={view} variant="filled" sx={{ minWidth: '100%' }} >
                <InputLabel id="demo-simple-select-filled-label">Implementation Type</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={type}
                  onChange={handleTypeChange}
  
  
                >
                  {items.map((item, index) => {
                    return (
                      <MenuItem value={item.Value} key={index}>
                        {item.Text}
                      </MenuItem>)
                  })}
  
                </Select>
  
  
              </FormControl>
            </Item>
          </Grid>
  
  
  
        </Grid>
        <Stack spacing={2} sx={{ width: '100%', mt: 1 }} style={{ height: '100%' }}>
          <Box sx={{ height: 'auto'}}>
  
            <TableContainer sx={{ marginTop: '20px'}} >
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {column.map((col, index) => {
                      return (
                        <TableCell key={index} sx={{ minWidth: '80px' }} className={index === 0 ? 'table__header': 'table__header text__right'}>{col.field}</TableCell>
                      )
                    })}
  
                  </TableRow>
  
                </TableHead>
                <TableBody>
  
                  {row.map((r, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{r.RoleName}</TableCell>
                        <TableCell className="text__right">{r.FTE}</TableCell>
                        <TableCell className="text__right">{r.Hrs}</TableCell>
                        <TableCell className="text__right">{currencyFormatter(r.CostHrRate)}</TableCell>
                        <TableCell className="text__right">{currencyFormatter(r.Cost)}</TableCell>
                        <TableCell className="text__right">{currencyFormatter(r.Cost / 12)}</TableCell>
                      </TableRow>
                    )
                  })}
  
                  <TableRow className='row-total table__header'>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell className="text__right">{SumofCoulmn(row, 'Hrs')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell className="text__right">{currencyFormatter(SumofCoulmn(row, 'Cost'))}</TableCell>
                    <TableCell className="text__right">{currencyFormatter(SumofCoulmn(row, 'Cost') / 12)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ borderBottom: "0px solid" }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} style={{fontWeight:"bold"}}>Duration of Support</TableCell>
                    <TableCell style={{fontWeight:"bold"}} className="text__right editable__feild">
                        <EditableTableCell
                          view={view}
                          value={week} 
                          dollarSign="false"
                          type="number"
                          onChange={(newValue) =>
                              handleCellChange(newValue) // to change duration of support value
                          }
                          /></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
  
  
            <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleBack} sx={{ marginTop: '20px',marginRight:'20px', visibility: view ? 'hidden' : 'visible' }}>
              <NavigateBeforeIcon/>Back
            </Button>
            <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleNext} sx={{ marginTop: '20px', visibility: view ? 'hidden' : 'visible' }}>
              Next<NavigateNextIcon/>
            </Button>
          </Box>
        </Stack>
  
  
  
  
      </Box>
    }
    </>

  )
}

export default AnualSupportCost;


const columns = [
  {
    field: 'Role',
  },
  {
    field: 'FTE',

  },
  {
    field: 'Effort',

  },
  {

    field: 'Cost Hr Rate',

  },
  {
    field: 'Annual Cost',

  },
  {
    field: 'Monthly Cost',

  }
];






