// assets
import { IconHome } from '@tabler/icons-react';

// constant
const icons = { IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const home = {
  "Key": 'home',
  "Type": 'group',
  "Url": '/home',
  "Items": [
    {
      "Key": 'home',
      "Text": 'Home',
      "Type": 'item',
      "Url": '/home',
      icon: icons.IconHome,
      breadcrumbs: false
    }
  ]
};

export default home;
