import { useSelector } from 'react-redux';
import { useState, createContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useCookies } from 'react-cookie';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// AppContext API - added to improve performance
const AppContext = createContext();

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const [refreshMenus, setRefreshMenus] = useState(0);
  const [cookie, setCookie, removeCookie] = useCookies(['Token']);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AppContext.Provider value={{ cookieValue: [cookie, setCookie], removeCookieValue: [removeCookie], menuValue: [refreshMenus], setMenuValue : [setRefreshMenus] }}>
            <Routes />
          </AppContext.Provider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
export { AppContext };