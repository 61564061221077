import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ClientCustomCost from './ClientCustomCost';
import BuildSetupCost from './BuildSetupCost';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import { confirmChangeTab } from '../Constfunctions';

export default function Teamandcost({ view, setTab, ClientId, ProjectId, EstimationId, Version, isChangedPrompt, setDirty, setNotDirty }) {
  const [activeTab, setActiveTab] = useState('Build-&-Setup-Cost');
  // For total values
  const [buildSetupCost, setBuildSetupCost] = useState(0);

  const handleChange = (event, newValue) => {
    const leaveTab = confirmChangeTab(isChangedPrompt, setNotDirty);
    if(leaveTab !== null){
      setActiveTab(newValue);
    }

  };

  const handleNext = (e) => {
    e.preventDefault();
    const leaveTab = confirmChangeTab(isChangedPrompt, setNotDirty);
    if(leaveTab !== null){
      setTab('Annual-Support-Cost');
    }
  }

  const handleBack = (e) => {
    e.preventDefault();
    const leaveTab = confirmChangeTab(isChangedPrompt, setNotDirty);
    if(leaveTab !== null){
      setTab('Client-Custom-Change');
    }
    
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} textColor="secondary"
            indicatorColor="secondary" aria-label="lab API tabs example">
            <Tab label="Build & Setup Cost" value="Build-&-Setup-Cost" />
            <Tab label="Client Customization Cost" value="Client-Customization-Cost" />
          </TabList>
        </Box>
        <TabPanel value="Build-&-Setup-Cost"><BuildSetupCost view={view} setBuildSetupCost={setBuildSetupCost} ClientId={ClientId} ProjectId={ProjectId} EstimationId={EstimationId} Version={Version} setDirty={setDirty} setNotDirty={setNotDirty} /></TabPanel>
        <TabPanel value="Client-Customization-Cost"><ClientCustomCost view={view} buildSetupCost={buildSetupCost} ClientId={ClientId} ProjectId={ProjectId} EstimationId={EstimationId} Version={Version} setDirty={setDirty} setNotDirty={setNotDirty}  /></TabPanel>
      </TabContext>
      <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '10px', marginRight: '20px', visibility: view ? 'hidden' : 'visible' }} onClick={handleBack}>
        <NavigateBeforeIcon />Back</Button>

      <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '10px', visibility: view ? 'hidden' : 'visible' }} onClick={handleNext}>
        Next<NavigateNextIcon /></Button>
    </Box>
  );

}